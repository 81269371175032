export interface Theme {
  primaryColor: string;
  secondaryColor: string;
  /* colors */
  white: string;
  grayLight: string;
  black: string;
}

const DefaultTheme = {
  primaryColor: '#EB5951',
  secondaryColor: '#f2f2f2',
  white: '#fff',
  grayLight: '#ccc',
  black: '#313131'
};

export { DefaultTheme };

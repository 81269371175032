import { useContext, useState, useMemo } from 'react'
import isEqual from 'lodash/isEqual'
import { GridContext } from '../GridContext';
import type { ResponseStatus } from '../../types/types'
import firebase from '../../firebase/firebase';

type GridContentProps = {
  onDone: () => void,
  content: string[],
  rows: number,
  cols: number
}

type FormData = {
  selectValue: string,
  content: string[]
}

type GridContentResult = {
  contentPresets: string[],
  formData: FormData,
  onUpdateContent: (content: string[]) => Promise<void>,
  onPresetChange: (key: string) => void
  onInputChange: (index: number, value: string) => void,
  status: ResponseStatus,
}

/* Helper(s) */
const PRESETS: Record<string, string[]> = {
  'ABCD': ['A', 'B', 'C', 'D'],
  'Hiring': ['Reject', 'No Hire', 'Hire', 'Strong Hire'],
  'Fibonacci': ['1', '2', '3', '5', '8', '13', '21', '∞', '?'],
}

/*
  Get the initial selected value if content is a preset
*/
const getInitialSelectedValue = (content: string[]): string =>
  Object.entries(PRESETS).find(([, value]) => isEqual(value, content))?.[0] ?? ''

/*
  Hook used to process the update of the grid content
*/
export const useGridContent = ({ onDone, cols, rows, content }: GridContentProps): GridContentResult => {
  /*
    TODO: integrate react query so that we can invalidate the previous state
    and not have to use a context hook to resync
  */
  const gridContext = useContext(GridContext)

  /* Form data state */
  const [formData, setFormData] = useState({
    selectValue: getInitialSelectedValue(content),
    content: [...content]
  });

  /* Form status */
  const [status, setStatus] = useState<ResponseStatus>('idle')

  /*
    filter the presets based for grid style
  */
  const contentPresets = useMemo(() => {
    const target = rows * cols
    return Object.keys(PRESETS).filter((key) => PRESETS[key].length === target)
  }, [rows, cols])

  /*
    Handle the change which is performant given we are dealing with small array
    of primitives
  */
  const onInputChange = (index: number, value: string): void => {
    const newContent = [...formData.content]
    newContent[index] = value

    setFormData({
      selectValue: getInitialSelectedValue(newContent),
      content: newContent
    });
  };

  /* Preset change handler */
  const onPresetChange = (key: string): void => {
    setFormData({
      content: PRESETS[key],
      selectValue: key
    })
  }

  /* Process the content update */
  const onUpdateContent = async (newContent: string[]): Promise<void> => {
    if (gridContext && gridContext?.userId) {
      setStatus('loading')
      const { gridId, userId, onUpdateContentState } = gridContext
      try {
        const grid = await firebase.updateContent(gridId, userId, newContent)
        // just to satisfy TS, the core firebase module needs a refactor
        if (grid) {
          onUpdateContentState(grid)
        }
        setStatus('success')
        onDone()
      } catch (e) {
        setStatus('error')
        // eslint-disable-next-line no-console
        console.error(e)
      }
    }
  }

  return {
    contentPresets,
    formData,
    onPresetChange,
    onUpdateContent,
    onInputChange,
    status,
  }
}

import { FirebaseAuth, FirebaseAuthWrapper } from './auth';
import { FirebaseCoreWrapper, FirebaseCore } from './core';

export { onValue, set, child } from 'firebase/database';
export type { DataSnapshot } from 'firebase/database';

interface FirebaseWrapper extends FirebaseAuthWrapper, FirebaseCoreWrapper {}

const Firebase: FirebaseWrapper = {
  ...FirebaseCore,
  ...FirebaseAuth,
};

export default Firebase;

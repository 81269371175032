import { getDatabase, ref, update } from 'firebase/database';
import { signInAnonymously, getAuth } from 'firebase/auth';

export interface FirebaseAuthWrapper {
  getUserId: () => string | undefined;
  login: () => Promise<string>;
}

const FirebaseAuth: FirebaseAuthWrapper = {
  async login(): Promise<string> {
    try {
      const { user } = await signInAnonymously(getAuth());

      const db = ref(getDatabase(), `users/${user.uid}`);

      await update(db, { uid: user.uid });

      return user.uid;
    } catch {
      throw new Error('problem logging in');
    }
  },

  getUserId(): string | undefined {
    return getAuth().currentUser?.uid;
  },
};

export { FirebaseAuth };

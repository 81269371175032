import { useState, useCallback, type FC } from 'react';
import { nanoid } from 'nanoid';
import { useNavigate } from 'react-router-dom';
import { Create } from './Create';
import firebase from '../firebase/firebase';

export type GridLayout = '2x2' | '3x3';

const CreateContainer: FC = () => {
  const [creating, setCreating] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const navigate = useNavigate();

  const create = useCallback(
    async (rows: number, cols: number, content: string[]): Promise<void> => {
      if (!creating) {
        setCreating(true);

        const gridId: string = nanoid(10);
        const userId: string = await firebase.login();
        const newGrid = await firebase.createBoard(
          gridId,
          userId,
          rows,
          cols,
          content,
        );

        if (newGrid) {
          navigate(`/${gridId}`, {
            state: newGrid,
          });
        } else {
          setError(true);
          setCreating(false);
        }
      }
    },
    [creating, navigate],
  );

  const createGrid = useCallback(
    (layout: GridLayout): void => {
      const create3x3Grid = (): void => {
        create(3, 3, ['1', '2', '3', '5', '8', '13', '21', '∞', '?']);
      };

      const create2x2Grid = (): void => {
        create(2, 2, ['A', 'B', 'C', 'D']);
      };

      switch (layout) {
        case '2x2':
          create2x2Grid();
          break;
        case '3x3':
          create3x3Grid();
          break;
        default:
          throw Error(`Grid layout ${layout} not supported!`);
      }
    },
    [create],
  );

  return (
    <Create creating={creating} onCreateHandler={createGrid} error={error} />
  );
};

export { CreateContainer };

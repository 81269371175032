import styled from 'styled-components';

const Button = styled.button`
  background-color: ${p => p.theme.white};
  border: 0.0625rem solid ${p => p.theme.primaryColor};
  padding: 0.75rem;
  text-align: center;
  border-radius: 0.375rem;
  font-size: 1.3rem;
  :hover {
    background-color: ${p => p.theme.secondaryColor};
    cursor: pointer;
  }
`;

export { Button };

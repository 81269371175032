import { Route, Routes, BrowserRouter } from 'react-router-dom';
import styled from 'styled-components';
import firebase from '../firebase/firebase';
import { GridLoaderContainer } from '../grid/GridLoaderContainer';
import { Footer } from '../shared/Footer';
import { Terms } from './Terms';
import { CreateContainer } from '../create/CreateContainer';

firebase.init();

const AppLayout = styled.div`
  flex-direction: column;
  display: flex;
  width: 100%;
  height: 100%;
`;

const App: React.FunctionComponent = () => (
  <AppLayout>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<CreateContainer />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/:gridId" element={<GridLoaderContainer />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  </AppLayout>
);

export { App };

import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { GridContainerProps } from './GridContainer';
import { GridContext } from './GridContext'
import firebase from '../firebase/firebase';
import { GridLoader } from './GridLoader';


const GridLoaderContainer: React.FunctionComponent = () => {
  const location = useLocation();
  const [gridProps, setGridProps] = useState<GridContainerProps | undefined>(location?.state);
  const [loading, setLoading] = useState<boolean>(!gridProps);
  const [userId, setUserId] = useState<string | undefined>(gridProps?.userId);

  const gridId: string = location.pathname.replace('/', '');

  useEffect(() => {
    const loadGrid = async (): Promise<void> => {
      if (!gridProps) {
        const currentUserId = await firebase.login();
        setUserId(currentUserId)

        const grid = await firebase.loadGrid(gridId, currentUserId);
        setGridProps(grid);
        setLoading(false);
      }
    };

    loadGrid();
  }, [gridId, gridProps]);

  const onUpdateContentState = useCallback((grid: GridContainerProps): void => {
    setGridProps(grid)
    /* Update window state without a re-render */
    window.history.replaceState({}, JSON.stringify(grid))
  }, [])

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <GridContext.Provider value={{ onUpdateContentState, gridId, userId }}>
      <GridLoader loading={loading} grid={gridProps} />
    </GridContext.Provider>
  )
};

export { GridLoaderContainer };

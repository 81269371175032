import React from 'react';
// eslint-disable-next-line import/no-unresolved
import { createRoot } from 'react-dom/client';
import './index.css';
import { ThemeProvider } from 'styled-components';
import { App } from './app/App';
import { DefaultTheme } from './theme';

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
  <ThemeProvider theme={DefaultTheme}>
    <App />
  </ThemeProvider>,
);

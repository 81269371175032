import React, {
  useRef,
  useEffect,
  useState,
  RefObject,
  type JSX,
  useCallback,
} from 'react';
import styled from 'styled-components';
import isEqual from 'lodash/isEqual';
import { CountDown } from './CountDown';
import { Vote } from './GridContainer';
import { GridContentEditor } from './GridContentEditor/GridContentEditor'
import { AnimatedVote } from './AnimatedVote';
import { AdminButton } from './AdminButton';
import { ConnectionLost } from '../ConnectionLost';
import Dialog from '../shared/Dialog';
import { SettingsButton } from './SettingsButton';

export interface GridProps {
  id: string;
  rows: number;
  cols: number;
  votes: Vote[];
  myVote: Vote | undefined;
  content: string[];
  voteInProgress: boolean;
  isAdmin: boolean;
  connected: boolean;
  onAdminButtonClicked: () => void;
  onClick: (event: VoteCoords) => void;
}

function createGridCells(
  rows: number,
  cols: number,
  content: string[],
): JSX.Element[] {
  const cells = [];

  for (let r = 0; r < rows; r += 1) {
    for (let c = 0; c < cols; c += 1) {
      cells.push(
        <GridCell key={`cell-${r}-${c}`} rows={r * 2 + 2} cols={c * 2 + 2}>
          <Content>{content[r * cols + c] || ''}</Content>
        </GridCell>,
      );
    }
  }

  for (let r = 0; r < rows - 1; r += 1) {
    cells.push(
      <GridLine
        key={`line-h${r}`}
        id="row-divider"
        rowStart={r * 2 + 3}
        rowEnd={r * 2 + 3}
        colStart={2}
        colEnd={cols * 2 + 1}
      />,
    );
  }

  for (let c = 0; c < cols - 1; c += 1) {
    cells.push(
      <GridLine
        key={`line-v${c}`}
        id="col-divider"
        rowStart={2}
        rowEnd={rows * 2 + 1}
        colStart={c * 2 + 3}
        colEnd={c * 2 + 3}
      />,
    );
  }

  return cells;
}

const GridTouchArea = styled.div`
  flex: 1;
  position: relative;
`;

const GridFlexContainer = styled.div`
  flex-direction: column;
  align-items: center;
  display: flex;
  height: 100%;
`;

const GridDiv = styled.div<GridLayout>`
  display: grid;
  grid-template-rows: 5px repeat(${(props) => props.rows}, 1fr 5px);
  grid-template-columns: 5px repeat(
      ${(props: GridLayout) => props.cols},
      1fr 5px
    );
  gap: 5px;
  flex: 1;
  box-sizing: border-box;
  padding: 20px;
  width: 100%;
  @media (min-width: 1080px) {
    width: 1080px;
  }
  :hover {
    cursor: pointer;
  }
  user-select: none;
`;

const AdminRowDiv = styled.div`
  max-width: 67.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
`

const GridCell = styled.div<GridCellProps>`
  background-color: ${(p) => p.theme.secondaryColor};
  border-radius: 20px;
  grid-column-start: ${(p) => p.cols};
  grid-row-start: ${(p) => p.rows};
  position: relative;
`;

const Content = styled.p`
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24pt;
`;

const GridLine = styled.div<GridLineProps>`
  grid-column-start: ${(p) => p.colStart};
  grid-column-end: ${(p) => p.colEnd};
  grid-row-start: ${(p) => p.rowStart};
  grid-row-end: ${(p) => p.rowEnd};
  border-radius: 30px;
  background-color: ${(p) => p.theme.primaryColor};
`;

const ButtonContainer = styled.div`
  padding: 1rem 0 0;
  padding: 0;
  position: absolute;
  bottom: 1rem;
  right: 1.6rem;
`

interface GridCellProps {
  rows: number;
  cols: number;
}

interface GridLineProps {
  colStart: number;
  colEnd: number;
  rowStart: number;
  rowEnd: number;
}

interface GridLayout {
  rows: number;
  cols: number;
}

export interface VoteCoords {
  x: number;
  y: number;
}

interface GridDimensions {
  width: number;
  height: number;
  x: number;
  y: number;
}

function getDimensions(ref: RefObject<HTMLDivElement>): GridDimensions {
  if (ref.current) {
    const rect = ref.current.getBoundingClientRect();

    return {
      width: rect.width,
      height: rect.height,
      x: rect.left,
      y: rect.top,
    };
  }
  return {
    width: 0,
    height: 0,
    x: 0,
    y: 0,
  };
}

const Grid: React.FunctionComponent<GridProps> = (props: GridProps) => {
  const {
    rows,
    connected,
    cols,
    content,
    isAdmin,
    onAdminButtonClicked,
    onClick,
    voteInProgress,
    votes,
    myVote,
  } = props;

  const ref: RefObject<HTMLDivElement> = useRef(null);
  const [dimensions, setDimensions] = useState<GridDimensions>(
    getDimensions(ref),
  );

  const [showDialog, setShowDialog] = useState<boolean>(false)
  const cells = createGridCells(rows, cols, content);

  useEffect(() => {
    const dimens: GridDimensions = getDimensions(ref);
    if (!isEqual(dimensions, dimens)) {
      setDimensions(dimens);
    }

    const handleResize = (): void => {
      setDimensions(getDimensions(ref));
    };

    // eslint-disable-next-line no-undef
    window.addEventListener('resize', handleResize);
    return () => {
      // eslint-disable-next-line no-undef
      window.removeEventListener('resize', handleResize);
    };
  }, [dimensions]);

  const handleTouch = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
      const width = dimensions.width || 1;
      const height = dimensions.height || 1;

      onClick({
        x: (event.clientX - dimensions.x) / width,
        y: (event.clientY - dimensions.y) / height,
      });
    },
    [dimensions.height, dimensions.width, dimensions.x, dimensions.y, onClick],
  );

  return (
    <GridTouchArea>
      {!connected && <ConnectionLost />}
      <GridFlexContainer>
        {isAdmin && showDialog && (
          <Dialog onClose={() => setShowDialog(false)}>
            <GridContentEditor
              content={content}
              rows={rows}
              cols={cols}
              onDone={() => setShowDialog(false)}
            />
          </Dialog>
        )}
        <GridDiv
          id="Grid"
          onClick={handleTouch}
          ref={ref}
          rows={rows}
          cols={cols}
        >
          {cells}
        </GridDiv>

        {isAdmin && (
          <AdminRowDiv>
            <AdminButton onClickHandler={onAdminButtonClicked} />
            <ButtonContainer>
              <SettingsButton onClick={() => setShowDialog(true)} />
            </ButtonContainer>
          </AdminRowDiv>
        )}

      </GridFlexContainer>

      {voteInProgress && <CountDown duration={5} />}

      {!voteInProgress &&
        votes.map((vote) => (
          <AnimatedVote
            key={`vote-${vote.x}${vote.y}${vote.emoji}`}
            x={dimensions.x + vote.x * dimensions.width}
            y={dimensions.y + vote.y * dimensions.height}
            emoji={vote.emoji}
            delay={Math.random() * 0.75}
          />
        ))}

      {myVote && (
        <AnimatedVote
          key={`myVote_${myVote.x}_${myVote.y}`}
          x={dimensions.x + myVote.x * dimensions.width}
          y={dimensions.y + myVote.y * dimensions.height}
          emoji={myVote.emoji}
          delay={0}
        />
      )}
    </GridTouchArea>

  );
};

export { Grid };

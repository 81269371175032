import type { FC } from 'react';
import { Button } from '../shared/Button';

export interface AdminButtonProps {
  onClickHandler: () => void;
}

const AdminButton: FC<AdminButtonProps> = ({
  onClickHandler,
}: AdminButtonProps) => (
  <Button
    id="admin-button"
    onClick={(event: React.MouseEvent<HTMLElement>) => {
      onClickHandler();
      event.stopPropagation();
    }}
  >
    Start voting
  </Button>
);

export { AdminButton };

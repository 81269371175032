import React from 'react';
import styled from 'styled-components';
import { Button } from '../../shared/Button'
import { AlignRight } from '../../shared/AlignRight'
import { useGridContent } from './useGridContentHook';
import { config } from '../../config'

const FormContainer = styled.div`
  margin: 0 auto;
  width: 37.5rem;
  box-sizing: border-box;
  border-radius: 0.3125rem;
`;

const VRow = styled.div`
  margin: 1rem;
`;

const Error = styled.span`
  color: ${p => p.theme.primaryColor};
  margin: 0 1rem;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.5rem;
  font-size: 1.2rem;
  border: 0.0625rem solid ${(p) => p.theme.grayLight};
  border-radius: 0.1875rem;
`;

const Select = styled.select`
  width: 100%;
  padding: 0.625rem;
  font-size: 1.2rem;
  border: 0.0625rem solid ${(p) => p.theme.grayLight};
  border-radius: 0.1875rem;
`;

interface GridLayout {
  rows: number;
  cols: number;
}

const GridContainer = styled.div<GridLayout>`
  display: grid;
  grid-template-rows: repeat(${(props) => props.rows}, 1fr);
  grid-template-columns: repeat(
      ${(props: GridLayout) => props.cols},
      1fr
    );
  gap: 0.8rem;
`
 
type GridContentEditorProps = {
  onDone: () => void
  content: string[]
  rows: number
  cols: number
}

/* Helper(s) */

export const GridContentEditor = ({ onDone, rows, cols, content }: GridContentEditorProps): React.ReactElement => {
  const {
    contentPresets,
    formData,
    onPresetChange,
    onUpdateContent,
    onInputChange,
    status,
  } = useGridContent({
    content,
    cols,
    rows,
    onDone
  })

  const loading = status === 'loading'
  const error = status === 'error'

  const handleSubmit = (e: React.FormEvent): void => {
    e.preventDefault();
    onUpdateContent(formData.content)
  };

  return (
    <FormContainer>
      <form onSubmit={handleSubmit}>
        <VRow>
          <Select
            name="contentSelector"
            value={formData.selectValue}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onPresetChange(e.target.value)}>
            <option value="">Choose a preset</option>
            {contentPresets.map((key) => (
              <option key={key}>{key}</option>
            ))}
          </Select>
        </VRow>
        <VRow>
          <GridContainer rows={rows} cols={cols}>
            {formData.content.map((value, index) => (
              <Input
                key={`item_${index + 1}`}
                type="text"
                maxLength={config.constants.contentMaxLength}
                name={`item_${index + 1}`}
                value={value}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(index, e.target.value)}
              />
            ))}
          </GridContainer>
        </VRow>
        <VRow>
          <AlignRight>
            {error && <Error>Oops, something went wrong</Error>}
            <Button data-testid="submit" disabled={loading}>
              {loading ? 'Updating' : 'Update'}
            </Button>
          </AlignRight>
        </VRow>
      </form>
    </FormContainer>
  );
}

import type { FC } from 'react';
import styled from 'styled-components';

const TermsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-grow: 1;
  width: 100%;
  flex-direction: column;
`;

const TermsDiv = styled.div`
  @media (min-width: 1080px) {
    width: 1080px;
  }
  font-size: 14pt;
  padding: 20px;
  margin: auto;
`;

const TermsUppercase = styled.p`
  text-transform: uppercase;
`;

const Terms: FC = () => (
  <TermsContainer>
    <TermsDiv>
      <p>
        Permission is hereby granted, free of charge, to any person (i)
        obtaining a copy of this software and associated documentation files
        (the &quot;Software&quot;); and/or (ii) using the GRID services (the
        &quot;Services&quot;), to deal in the Software or use the Services
        without restriction, including without limitation the rights to use,
        copy, modify, merge, publish, distribute, sublicense, and/or sell copies
        of the Software or the Services, and to permit persons to whom the
        Software and the Services are furnished to do so, subject to the
        following conditions:
      </p>
      <p>
        The above copyright notice and this permission notice shall be included
        in all copies or substantial portions of the Software and shall be set
        out wherever the Services are hosted.
      </p>
      <TermsUppercase>
        The software and the services are provided &quot;as is&quot;, without
        warranty of any kind, express or implied, including but not limited to
        the warranties of merchantability, fitness for a particular purpose and
        noninfringement. In no event shall the authors or copyright holders be
        liable for any claim, damages or other liability, whether in an action
        of contract, tort or otherwise, arising from, out of or in connection
        with the software or services or the use or other dealings in the
        software or services.
      </TermsUppercase>
    </TermsDiv>
  </TermsContainer>
);

export { Terms };

import React, { FC } from 'react';
import styled, { keyframes } from 'styled-components';

interface CountDownProps {
  duration: number;
}

const expandRightThenHide = keyframes`
  0% {
    transform: scaleX(0);
  }

  90% {
    transform: scaleX(1);
  }

  95% {
    transform: scaleX(1);
  }

  100% {
    transform: scaleY(0);
  }
`;

const CountDownRect = styled.div<CountDownRectProps>`
  background-color: ${(p) => p.theme.primaryColor};
  transform-origin: 0% 0%;
  animation: ${expandRightThenHide} ${(p) => p.duration}s linear forwards;
  height: 15px;
  width: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
`;

interface CountDownRectProps {
  duration: number;
}

interface CountDownProps {
  duration: number;
}

const CountDown: FC<CountDownProps> = (props) => {
  const { duration } = props;

  return <CountDownRect id="countdown" duration={duration} />;
};

export { CountDown };

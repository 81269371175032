import React, { ReactElement, useEffect, useRef } from 'react';
import styled from 'styled-components';

// Define TypeScript types for props
interface DialogProps {
  children: React.ReactNode;
  onClose: () => void
}

// Styled components for the dialog
const DialogWrapper = styled.dialog`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  margin: 0;
  border: none;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const DialogContent = styled.div`
  background: white;
  padding: 1.25rem;
  border-radius: 0.3125rem;
  box-shadow: 0px 0px 0.625rem rgba(0, 0, 0, 0.3);
`;

const CloseButton = styled.button`
  position: absolute;
  color: white;
  top: 0.625rem;
  right: 0.625rem;
  background: none;
  border: none;
  font-size: 2.1875rem;
  cursor: pointer;
`;

function Dialog({ children, onClose }: DialogProps): ReactElement {
  const dialogRef = useRef<HTMLDialogElement>(null)

  useEffect(() => {
    dialogRef?.current?.showModal()
  }, [dialogRef])

  return (
    <DialogWrapper ref={dialogRef} onCancel={onClose}>
      <DialogContent>
        <CloseButton onClick={onClose}>X</CloseButton>
        {children}
      </DialogContent>
    </DialogWrapper>
  );
}

export default Dialog;

import { initializeApp } from 'firebase/app';
import {
  type DatabaseReference,
  ref,
  getDatabase,
  onValue,
  set,
  update,
  type Database,
  connectDatabaseEmulator,
} from 'firebase/database';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { GridContainerProps } from '../grid/GridContainer';
import { config } from '../config';

export interface FirebaseCoreWrapper {
  init: () => void;
  database?: Database;
  loadGrid: (
    gridId: string,
    userId: string,
  ) => Promise<GridContainerProps | undefined>;
  getDatabase: () => Database;
  updateContent: (gridId: string, userId: string, content: string[]) => Promise<GridContainerProps | undefined>;
  createBoard: (
    gridId: string,
    userId: string,
    rows: number,
    cols: number,
    content: string[],
  ) => Promise<GridContainerProps | undefined>;
  votesDb: (id: string) => DatabaseReference;
  votingDb: (id: string) => DatabaseReference;
  connectivity: () => DatabaseReference;
}

const FirebaseCore: FirebaseCoreWrapper = {
  init(): void {
    const app = initializeApp(config.firebase);
    this.database = getDatabase(app);

    if (process.env.REACT_APP_USE_FIREBASE_EMULATOR === 'true') {
      connectAuthEmulator(getAuth(), 'http://127.0.0.1:9099');
      connectDatabaseEmulator(this.database, '127.0.0.1', 9000);
    }
  },

  getDatabase(): Database {
    if (!this.database) throw new Error('Firebase has not be initialised');

    return this.database;
  },

  loadGrid(
    gridId: string,
    userId: string,
  ): Promise<GridContainerProps | undefined> {
    const boardRef = ref(this.getDatabase(), `boards/${gridId}`);

    return new Promise<GridContainerProps | undefined>((resolve) => {
      if (gridId === '') {
        resolve(undefined);
      } else {
        onValue(boardRef, (snapshot) => {
          if (snapshot.exists()) {
            const grid = snapshot.val();
            const { layout } = grid;

            resolve({
              id: gridId,
              rows: layout.rows,
              cols: layout.cols,
              content: layout.content,
              votes: grid.votes || {},
              voteInProgress: grid.voteInProgress,
              userId,
              userRole: grid.admin === userId ? 'admin' : 'user',
            });
          } else {
            resolve(undefined);
          }
        });
      }
    });
  },

  async createBoard(
    gridId: string,
    userId: string,
    rows: number,
    cols: number,
    content: string[],
  ) {
    const boardRef = ref(this.getDatabase(), `boards/${gridId}`);

    await set(boardRef, {
      layout: {
        rows,
        cols,
        content,
      },
      votes: {},
      voting: false,
      admin: userId,
    });

    return this.loadGrid(gridId, userId);
  },

  async updateContent(gridId: string, userId: string, content: string[]) {
    const boardRef = ref(this.getDatabase(), `boards/${gridId}`);

    await update(boardRef, {
      'layout/content': content,
    });

    return this.loadGrid(gridId, userId);
  },

  votesDb(id: string) {
    return ref(this.getDatabase(), `boards/${id}/votes`);
  },

  votingDb(id: string) {
    return ref(this.getDatabase(), `boards/${id}/voting`);
  },

  connectivity() {
    return ref(this.getDatabase(), '.info/connected');
  },
};

export { FirebaseCore };

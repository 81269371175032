import { useState, useEffect, useCallback, useMemo, type FC } from 'react';
import isEqual from 'lodash/isEqual';

import { Grid, VoteCoords } from './Grid';
import firebase, {
  onValue,
  set,
  child,
  type DataSnapshot,
} from '../firebase/firebase';
import { EMOJI_LIST } from './EmojiList';

export type Role = 'user' | 'admin';

export interface GridContainerProps {
  id: string;
  rows: number;
  cols: number;
  votes: { [key: string]: Vote };
  content: string[];
  voteInProgress: boolean;
  userId: string;
  userRole: Role;
}

export interface Vote {
  x: number;
  y: number;
  emoji: string;
}

function randomEmoji(currentEmoji?: string): string {
  if (currentEmoji && ['🥚', '🍳'].includes(currentEmoji)) return '🍳';
  if (Math.random() < 0.001) return '🥚';
  return EMOJI_LIST[Math.floor(Math.random() * EMOJI_LIST.length)];
}

const GridContainer: FC<GridContainerProps> = ({
  id,
  userId,
  voteInProgress,
  rows,
  cols,
  content,
  votes,
  userRole,
}) => {
  // Remote state
  const [myVote, setMyVote] = useState<Vote | undefined>(votes[userId]);
  const [currVotes, setCurrentVotes] = useState<{ [key: string]: Vote }>(votes);
  const [voting, setVoting] = useState<boolean>(!!voteInProgress);
  const [connected, setConnected] = useState<boolean>(false);

  const votesDb = useMemo(() => firebase.votesDb(id), [id]);
  const votingDb = useMemo(() => firebase.votingDb(id), [id]);
  const connectivity = firebase.connectivity();

  useEffect(() => {
    const updateVotes = (snapshot: DataSnapshot): void => {
      const newVotes = snapshot.exists() ? snapshot.val() : {};

      if (!isEqual(newVotes, currVotes)) {
        setCurrentVotes(newVotes);
      }
    };

    const unsubscribe = onValue(votesDb, updateVotes);

    return unsubscribe;
  }, [currVotes, votesDb]);

  useEffect(() => {
    const updateVoting = (snapshot: DataSnapshot): void => {
      if (snapshot.exists() && snapshot.val() !== voting) {
        const newVoting: boolean = snapshot.val();
        if (newVoting) {
          setMyVote(undefined);
        }
        setVoting(newVoting);
      }
    };

    const unsubscribe = onValue(votingDb, updateVoting);

    return unsubscribe;
  }, [votingDb, voting]);

  useEffect(() => {
    const updateConnectivity = (snapshot: DataSnapshot): void =>
      setConnected(snapshot.val());

    const unsubscribe = onValue(connectivity, updateConnectivity);

    return unsubscribe;
  }, [id, connectivity]);

  const handleTouch = useCallback(
    (coords: VoteCoords): void => {
      // Voting not in-progress
      if (!voting) {
        return;
      }

      const vote: Vote = {
        x: coords.x,
        y: coords.y,
        emoji: randomEmoji(myVote && myVote.emoji),
      };

      setMyVote(vote);
      set(child(votesDb, userId), vote);
    },
    [myVote, userId, votesDb, voting],
  );

  const handleAdminButton = useCallback((): void => {
    // removed stored location state so that on reload page will fetch latest grid state
    // rather than state from on creation i.e. no votes
    window.history.replaceState({}, document.title);
    set(votingDb, true);
    set(votesDb, {});
    setTimeout(() => {
      set(votingDb, false);
    }, 5000);
  }, [votesDb, votingDb]);

  return (
    <Grid
      id={id}
      rows={rows}
      cols={cols}
      content={content}
      myVote={myVote}
      connected={connected}
      isAdmin={userRole === 'admin'}
      votes={Object.values(currVotes).filter(
        (vote: Vote): boolean => !isEqual(vote, myVote),
      )}
      voteInProgress={voting}
      onAdminButtonClicked={handleAdminButton}
      onClick={handleTouch}
    />
  );
};

export { GridContainer };

import type { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as ZLogo } from './assets/zoa-z-logo.svg';
import { ReactComponent as OaLogo } from './assets/zoa-oa-logo.svg';

const AnimatedZLogo = styled(ZLogo)`
  @keyframes rotateY {
    from {
      transform: rotateY(60deg);
      opacity: 80%;
    }

    to {
      transform: rotateY(360deg);
      opacity: 100%;
    }
  }

  opacity: 0%;
  animation: rotateY 1s linear 0.5s forwards;
`;

const AnimatedOaLogo = styled(OaLogo)`
  @keyframes rotateInAroundZCharacter {
    from {
      transform: rotate3d(0, 1, 0, -90deg);
    }

    to {
      transform: rotate3d(0, 0, 0, 0deg) translateX(7.5px);
    }
  }

  translate: -7.5px;
  transform: rotate3d(0, 1, 0, -90deg);
  transform-origin: left;
  animation: rotateInAroundZCharacter 0.25s linear 1.2s forwards;
`;

const Branding: FC = () => (
  <Link to="/">
    <AnimatedZLogo />
    <AnimatedOaLogo />
  </Link>
);

export { Branding };
